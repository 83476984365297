.curved-header .sTxt{
    font-size: 15px;
}
.register-header{
    font-family: 'Times New Roman', Times, serif;
    font-weight: normal;
    letter-spacing: 1px;
}
.vError{
    font-size: 13px;
    font-weight: normal;
    margin: 0;
    color: red;
}