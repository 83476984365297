.curved-header{
	height: 150px;
	width: 100%;
	background-color: #27ADB0;
	border-radius: 0 0 50% 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.curved-header .hTxt{
	margin: 0px;
	color: #FFF;
	font-size: 30px;
}
.curved-header .sTxt{
	margin: 0px;
	color: #FFF;
	font-size: 20px;
}
.studentRegister{
	display: flex;
	justify-content: center;

	margin: 30px 0px;
}
.register-header{
	text-align: center;
}
.studentRegister .container{
	background-color: #FFF;
	border: 2px solid #F5F5F5;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 30px;
	box-shadow: 8px 8px 8px #D1D1D1;
}

.studentRegister .container form p{
	background-color: #27ADB0;
	color: #FFF;
	padding: 2px 5px;
	margin-bottom: 10px;
}
.studentRegister .error{
	text-align: center;
	color: red;
}
.studentRegister .container .text{
	width: 350px;
	height: 30px;
}
.studentRegister .container .button{
	width: 100%;
	height: 30px;
	background-color: #27ADB0;
	color: #fff;
	outline: none;
	border: none;
	border-radius: 15px;
	margin-bottom: 10px;
	font-size: 17px;
	cursor: pointer;
}

@media screen and (max-width: 400px){
	.studentRegister .container .text{
		width: 250px;
	}
	.curved-header .hTxt{
		margin: 0px;
		color: #FFF;
		font-size: 20px;
	}
}