.page-container{
	margin-top: 60px;
	padding: 0px 10px 10px 10px;
	position: relative;
}
.page-container .welcome{
	margin: 0px;
	font-size: 17px;
}
.column1{
	position: absolute;
	top: 40px;
	left: 10px;
	width: 190px;
}
.user{
	background-color: #F5F5F5;
	height: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 7px;
	margin-bottom: 10px;
}
.column1 .initial{
	height: 150px;
	width: 150px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #27ADB0;
	color: #FFF;
	font-size: 50px;
	margin: 20px 20px;
}
.column1 .initial p{
	text-transform: uppercase;
}
.exam-status{
	background-color: #F5F5F5;
	height: 250px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 7px;
	padding: 10px;
	margin-bottom: 10px;
}
.column2{
	margin: 18px 325px 0px 200px;
	padding: 5px 5px;
	background-color: #F5F5F5;
	border-radius: 7px;
}
.column2 .details{
	background-color: #27ADB0;
	color: #FFF;
	padding: 2px 4px;
}
.column2 .text{
	margin-right: 7px;
	margin-left: 7px;
}
.column2 .course-tiles{
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}
.column2 .tile{
	width: 250px;
	margin-left: 10px;
	margin-bottom: 20px;
}
.column2 .up{
	display: flex;
	align-items: center;
	height: 30px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	background-color: #FFF;
	color: #000;
	padding-left: 10px;
}
.column2 .down{
	display: flex;
	align-items: center;
	height: 30px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #F5F5F5;
	box-shadow: 2px 2px 8px #D1D1D1;
	padding-left: 10px;
}
.column3{
	position: absolute;
	top: 40px;
	right: 10px;
}
.column3 p{
	margin: 0px;
}
.column3 img{
	height: 250px;
	width: 300px;
}

.column3 .Img-wrapper{
	border-radius: 7px;
	padding: 5px;
	box-shadow: 2px 2px 8px #D1D1D1;
	text-align: center;
	margin-bottom: 20px;
}

.column3 .wrap1:hover{
	position: relative;
	transform: translate(-10, -10)
}

.column3 .Img-wrapper p{
	font-size: 13px;
	font-weight: bold;
	color: #27ADB0;
}

@media screen and (max-width: 1023px){
	.column2{
		margin: 15px 225px 0px 200px;
		padding: 5px 5px;
		background-color: #F5F5F5;
		border-radius: 7px;
	}
	.column3 img{
		height: 150px;
		width: 200px;
	}
}
@media screen and (max-width: 767px){
	.column1{
		position: static;
		width: 100%;
		margin-top: 10px;
	}
	.column1 .initial{
		height: 200px;
		width: 200px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #27ADB0;
		color: #FFF;
		font-size: 80px;
	}
	.column2{
		position: static;
		margin: 10px 0px;
	}
	.column3{
		position: static;
		width: 100%;
		text-align: center;
	}
	.column3 .Img{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	.column3 .Img-wrapper{
		margin: 20px 10px;
	}
}