.diagram-box{
	position: fixed;
	top: 8rem;
	left: 3rem;
	z-index: 2;
	height: 15rem;
	width: 15rem;
	background-color: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}

.diagram-box .close{
	color: #FFF;
	margin: 0px 0px 10px 0px;
	cursor: pointer;
}
.diagram-box img{
	background-color: #FFF;
	height: 80%;
	width: 95%;
	margin: 0px;
}

@media screen and (min-width: 37rem){
	.diagram-box{
		position: fixed;
		top: 5rem;
		left: 10rem;
		z-index: 2;
		height: 25rem;
		width: 25rem;
	}
}

@media screen and (min-width: 55rem){
	.diagram-box{
		position: fixed;
		top: 5rem;
		left: 20rem;
		z-index: 2;
		height: 30rem;
		width: 30rem;
	}
}

