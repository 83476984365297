.header{
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	height: 55px;
	display: flex;
	align-items: center;
	box-shadow: 0px 2px 8px #D1D1D1;
	background-color: #FFF;
	padding: 0px 20px;
	z-index: 1;
}

.header img{
	width: 30px;
	height: 30px;
	margin-right: 10px;
}
.header-text{
	color: #27ADB0;
	font-size: 20px;
	margin: 0px 100px 0px 0px;
	line-height: 15px;
}
.logout{
	position: absolute;
	right: 20px;
	margin: 0px;
	background-color: #56CD47;
	color: #FFF;
	padding: 6px 20px;
	border-radius: 3px;
	cursor: pointer;
}
.course-title{
	position: absolute;
	right: 10px;
	margin: 0px 0px 0px 260px;
	line-height: 15px;
}

@media screen and (max-width: 767px){
	.header-text{
		display: none;
	}
}