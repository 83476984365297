.info{
	margin: 65px 10px 0px 10px;
	padding: 5px 15px;
	background-color: #F5F5F5;
	border-radius: 7px;
	display: flex;
	flex-wrap: wrap;
	font-family: Times New Roman;
}
.info .student-details{
	margin-right: 70px;
}
.studentPageLink p{
	text-align: right;
	font-size: 20px;
	text-decoration: underline;
	color: rgb(0, 0, 130);
	margin: 0px;
	padding: 10px 10px;
	cursor: pointer;
}
.Submitted{
	font-size: 30px;
	color: #27ADB0;
	padding: 0px 10px;
	text-align: center;
}
.footer{
	background-color: #27ADB0;
	color: #FFF;
	text-align: center;
	font-size: 15px;
	padding: 5px 0px;
}
.footer p{
	margin: 0px;
}