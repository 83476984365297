.submit{
	height: 200px;
	width: 200px;
	position: fixed;
	top: 150px;
	left: 40px;
	transform: (-50%, -50%);
	border-radius: 10px;
	background-color: #27ADB0;
	color: #FFF;
	padding: 10px;
	text-align: center;
}
.submit-option{
	margin-top: 35px;
}
.submit-option p{
	background-color: #FFF;
	color: #27ADB0;
	padding: 10px;
	margin: 10px 0px;
	border-radius: 10px;
	cursor: pointer;
}