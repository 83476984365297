.next{
	background-color: #27ADB0;
	color: #FFF;
	margin: 5px 0px;
	padding: 7px 15px;
	text-align: right;
	border-radius: 10px;
	float: right;
	overflow: hidden;
	cursor: pointer;
}
.previous{
	background-color: #27ADB0; 
	color: #FFF;
	margin: 5px 0px;
	padding: 7px 15px;
	border-radius: 10px;
	float: left;
	overflow: hidden;
	cursor: pointer;
}
.clear{
	clear: both;
}
@media screen and (max-width: 765px){
	.next, .previous{
		float: none;
	}
	
}