.Utme_header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    background-color: #fff;
    padding: .5rem 1rem;
    color: #27ADB0;
    box-shadow: 0px 1px 8px #27ADB0;
    z-index: 2;
}
.Utme_header-logo-wrapper{
    display: flex;
    align-items: center;
}
.Utme_header-logo{
    height: 1.7rem;
    width: 2rem;
    border-radius: .5rem;
    margin-right: .5rem;
}
.Utme_header-logo-text{
    text-transform: uppercase;
    margin: 0;
    font-size: 1rem;
}
.Utme_header-text{
    height: 20px;
    width: 4rem;
    margin: 0;
    padding: .3rem 1rem;
    border-radius: .7rem;
    box-shadow: 1px 1px 5px #27ADB0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Utme_dash_sec1{
    position: relative;
    margin-top: 3rem;
    background: linear-gradient(-135deg, #27ADB0, #27ADB0, #23D5AB, #23D5AB);
    padding: 3rem 1rem 8rem 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.particles{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.welcome-message{
    width: 100%;
    margin-right: 2rem;
}
.welcome-text{
    margin: 0;
    text-transform: uppercase;
    font-style: italic;
    font-family: 'Courier New', Courier, monospace;
    color: #fff;
    font-size: 2.5rem;
}
.welcome-first_name{
    margin: 0;
    color: #fff;
    text-align: center;
    font-size: 3rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.welcome-reg_number{
    margin: 0;
    text-transform: uppercase;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: #fff;
    text-align: center;
    font-size: 1.2rem;
}
.countdown_sec{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    width: 100%;
    margin-top: 3rem;
    border: 2px solid #fff;
    padding: 2rem 0rem;
}
.countdown-label{
    font-size: 1.5rem;
    margin: 0;
    font-family: 'Times New Roman', Times, serif;
}
.countdown-timer{
    font-size: 2rem;
    margin: 0;
}
.countdown-timer-label{
    font-size: .7rem;
    display: flex;
    margin: 0;
}

.countdown-timer-label-days{
    margin: 0rem 2rem 0rem 2rem;
}
.countdown-timer-label-hours{
    margin: 0rem 2rem 0rem 0rem;
}
.countdown-timer-label-minutes{
    margin: 0rem 1rem 0rem 0rem;
}
.countdown-timer-label-seconds{
    margin: 0rem 1rem 0rem 0rem;
}

.postponed-label{
    font-size: 2rem;
    margin-bottom: 0;
}

.Utme_dash_sec2{
    position: relative;
    padding: 18rem 1rem 1rem 1rem;
}
.features{
    position: absolute;
    top: -5rem;
    left: 50%;
    width: 85%;
    transform: translateX(-50%);
    border-top-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    box-shadow: 2px 2px 8px #27ADB0;
    background-color: #fff;
    color: #000;
    font-size: 1.2rem;
    padding: 0rem 1rem;
    margin-bottom: 3rem;
}
.features li{
    font-size: 18px;
}
.congrat-text{
    color: #27ADB0;
    font-size: 1.5rem;
    text-align: center;
}
.exam-instructions{
    border-radius: 10px;
    box-shadow: 0.5px 0.5px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin: 2rem 0rem;
}
.exam-instructions .subject-details{
    font-size: 17px;
}
.exam-instructions .exam-start-link{
    font-size: 30px;
	color: rgb(0, 0, 130);
	text-align: center;
    cursor: pointer;
}
.related-images{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 3rem;
}
.related-image{
    height: 15rem;
    width: 15rem;
}
.exam-disclaimer{
    font-size: .9rem;
    font-family: 'Poppins', sans-serif;
}




@media (min-width: 350px){
    .countdown_sec{
        padding: 2rem;
    }
}

@media (min-width: 20rem){
    .Utme_dash_sec2{
        padding: 18rem 1rem 1rem 1rem;
    }
}

@media (min-width: 25rem){
    .Utme_dash_sec2{
        padding: 15rem 1rem 1rem 1rem;
    }
}

@media (min-width: 30rem){
    .Utme_dash_sec2{
        padding: 12rem 1rem 1rem 1rem;
    }
}

@media (min-width: 40rem){
    .countdown-timer{
        font-size: 2.5rem;
    }
    .countdown-timer-label{
        font-size: 1rem;
    }
    /* .Utme_header-logo-text{
        font-size: 1.2rem;
    } */
    .Utme_dash_sec2{
        padding: 8rem 1rem 1rem 1rem;
    }
}

@media (min-width: 50rem){
    .welcome-message{
        width: max-content;
    }
    .welcome-first_name{
        padding-left: 8rem;
        text-align: start;
    }
    .welcome-reg_number{
        padding-left: 8rem;
        text-align: start;
    }
    .countdown-label{
        font-size: 2rem;
    }
    .Utme_dash_sec2{
        padding: 1rem 1rem;
    }
    .features{
        width: 40%;
        right: 2rem;
        transform: none;
    }
    .congrat-text{
        width: 40%;
    }
    .related-image{
        margin-right: 5rem;
        height: 17rem;
        width: 17rem;
    }
}

@media (min-width: 60rem){
    .welcome-message{
        margin-bottom: 2rem;
    }
    .countdown_sec{
        width: max-content;
        margin-right: 8rem;
        padding: 2rem 8rem;
        margin-top: 0;
    }
}