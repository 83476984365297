.body-container{
	background-color: #F5F5F5;
	padding: 65px 10px 10px 10px;
	position: relative;
}
.questions{
	margin-left: 200px;
}
.side{
	position: fixed;
	top: 65px;
	left: 10px;
	width: 200px;
}
.finish{
	cursor: pointer;
	color: #27ABD0;
}


@media screen and (max-width: 767px){
	.body-container{
		background-color: #F5F5F5;
		padding: 117px 10px 10px 10px;
		position: relative;
	}
	.questions{
		margin-left: 0px;
	}
	.side{
		position: static;
		width: 100%;
	}
	.exam-header{
		position: fixed;
		top: 55px;
		left: 0px;
		right: 0px;
		padding: 10px 10px;
		background-color: #FFF;
		border-bottom: 1px solid #F5F5F5;
	}
	.exam-header .timer{
		float: left;
	}
	.exam-header .finish{
		float: right;
		margin: 5px 0px;
	}
	.clear{
		clear: both;
	}
}