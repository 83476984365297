.curved-header{
	height: 150px;
	width: 100%;
	background-color: #27ADB0;
	border-radius: 0 0 50% 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.curved-header .hTxt{
	margin: 0px;
	color: #FFF;
	font-size: 30px;
}
.curved-header .sTxt{
	margin: 0px;
	color: #FFF;
	font-size: 15px;
}
.login{
	display: flex;
	justify-content: center;
	margin: 30px 0px;
}
.login-header{
	text-align: center;
}
.login-header img{
	height: 100px;
	width: 170px;
}
.login-container form{
	text-align: center;
	width: 350px;
}
.login-container .success{
	color: #27ADB0;
}
.login-container .error{
	color: red;
}
.input-div{
	margin: 30px 0px;
	position: relative;
	height: 40px;
	border: 2px solid #d9d9d9;
	transition: 0.5s
}
.focus{
	border-bottom: 2px solid #27ADB0;
}
.input{
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 0px 5px;
	border: none;
	outline: none;
	background: none;
	font-size: 15px;
}
.login-container a{
	margin: 0px;
	text-decoration: none;
	color: #000;
}
.login-container a:hover{
	color: #27ADB0;
}
.login-container .reg{
	margin-top: 40px;
	text-align: center;
}
.reg .ques{
	color: #999999;
}

.btn{
	width: 70%;
	height: 40px;
	background-color: #27ADB0;
	color: #fff;
	outline: none;
	border: none;
	border-radius: 15px;
	margin-bottom: 10px;
	font-size: 17px;
	cursor: pointer;
}

@media screen and (max-width: 400px){
	.login-container form{
		text-align: center;
		width: 280px;
	}
	.curved-header .hTxt{
		margin: 0px;
		color: #FFF;
		font-size: 20px;
	}
}