.question-container{
	border-radius: 10px;
	padding: 10px 10px;
	margin-bottom: 10px;
	background-color: #FFF;
}
.question-container h4{
	margin-top: 5px;
	margin-bottom: 10px;
}
.diagram-link{
	color: #27ABD0;
	cursor: pointer;
}
.option{
	display: flex;
	margin-bottom: 10px;
}

form p{
	margin: 0px;
}
form .radio{
	
	display: flex;
	margin-right: 15px;
}
form input{
	height: 15px;
	width: 15px;
}