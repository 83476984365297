.tag{
	margin: 0;
	padding: 5px;
	background-color: #FFF;
	border-radius: 10px;
}
.questionTag{
	display: flex;
	flex-wrap: wrap;
}
.answered{
	background-color: #27ADB0;
	color: #FFF;
}