.admin-container{
	margin-top: 65px;
	display: flex;
	justify-content: center;
	align-content: center;
}
.admin-container .sub-container{
	max-width: 800px;
	padding: 0px 15px 30px 15px;
}
.admin-container .sub-container h3{
	margin: 0px 0px 10px 0px;
}
.admin-container .sub-container .text{
	width: 300px;
	height: 30px;
}
.admin-container .sub-container .textarea{
	width: 300px;
	height: 100px;
}
.admin-container .sub-container select{
	margin-bottom: 20px;
}
.admin-container .sub-container .button{
	background-color: #27ADB0;
	color: #FFF;
	width: 100%;
	height: 30px;
	border-radius: 10px;
	font-size: 17px;
	outline: none;
	border: none;	
}
.admin-container .sub-container .box{
	background-color: #FFF;
	border: 2px solid #F5F5F5;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 30px;
	box-shadow: 8px 8px 8px #D1D1D1;
}
.admin-container .sub-container .details{
	background-color: #27ADB0;
	color: #FFF;
	padding: 2px 4px;
}

.admin-container .sub-container .brad{
	border-bottom: 2px solid #6D6969;
}
.admin-container .sub-container .tile{
	border-bottom: 2px solid #D1D1D1;
}
.admin-container .sub-container .nav{
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 10px;
}
.admin-container .sub-container .nav p{
	background-color: #27ADB0;
	color: #FFF;
	margin: 5px 5px;
	padding: 7px 15px;
	border-radius: 10px;
}
.admin-container .sub-container .error{
	color: red;
}
.admin-container .sub-container .register p{
	background-color: #27ADB0;
	color: #FFF;
	padding: 2px 5px;
	margin-bottom: 10px;
}

.admin-container .sub-container .upload-question .radio{
	display: flex;
}
.admin-container .sub-container .upload-question select{
	margin: 10px 0px;
}