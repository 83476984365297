.examLink{
	margin: 10px 325px 0px 200px;
	padding: 10px 15px;
	background-color: #F5F5F5;
	border-radius: 7px;
}
.examLink .link{
	font-size: 30px;
	color: rgb(0, 0, 130);
	text-align: center;
	cursor: pointer;
}

.examLink .btn{
	width: 70%;
	height: 40px;
	background-color: #27ADB0;
	color: #fff;
	outline: none;
	border: none;
	border-radius: 15px;
	margin-bottom: 10px;
	font-size: 17px;
}

@media screen and (max-width: 1023px){
	.examLink{
		margin: 10px 225px 0px 200px;
		padding: 5px 15px;
		background-color: #F5F5F5;
		border-radius: 7px;
	}
}
@media screen and (max-width: 767px){
	.examLink{
		margin: 10px 0px;
		padding: 5px 15px;
		background-color: #F5F5F5;
		border-radius: 7px;
	}
}